body {
  --main-color: #AA9903;
  --main-font: 'Arial';
  --main-bg-color: #f3eeee;
  --accent-color: #E13E09;
  --logo-width: 250px;

  margin: 0;
  font-family: -apple-system, var(--main-font),
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--main-color);

}

code {
  font-family: Monaco, Consolas, 'Roboto',
    monospace;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1{
  font-size: 2rem;
}
